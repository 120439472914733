import { extendTheme } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import {
  Button,
  Calendar,
  Checkbox,
  Container,
  FormLabel,
  Heading,
  Input,
  List,
  Menu,
  Modal,
  Radio,
  Select,
  Tabs,
  Text,
  Textarea,
} from '@theme/custom';
import { CalendarDefaultTheme } from '@uselessdev/datepicker';

export const breakpoints = {
  sm: '480px',
  md: '768px',
  lg: '992px',
  xl: '1280px',
};

export const theme = extendTheme(CalendarDefaultTheme, {
  breakpoints,
  components: {
    Button,
    Container,
    Checkbox,
    Heading,
    Tabs,
    Input,
    FormLabel,
    Select,
    Text,
    Textarea,
    Menu,
    Modal,
    Radio,
    List,
    ...Calendar,
  },
  colors: {
    ...colors,
  },

  styles: {
    global: {},
  },
});
