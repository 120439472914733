import { Button } from '@chakra-ui/react';
import { colors } from '@theme/colors';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const CookieConsentBanner = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(false);
  const [cookiesDeclined, setCookiesDeclined] = useState<boolean>(false);
  const nbDaysExpirationCookie = 30;

  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
    return undefined;
  };

  const setCookie = (name: string, value: string, days: number) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };

  useEffect(() => {
    const consent = getCookie('cookiesConsent');
    if (consent === 'accepted') {
      setCookiesAccepted(true);
    } else if (consent === 'declined') {
      setCookiesDeclined(true);
    }
  }, []);

  useEffect(() => {
    if (cookiesAccepted) {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-1BPTWNFKD2`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.GA_INITIALIZED = true;
        window.dataLayer = window.dataLayer || [];
        window.gtag = (...args) => {
          window.dataLayer.push(...args);
        };
        window.gtag('js', new Date());
        window.gtag('config', 'G-1BPTWNFKD2');
      };
    }
  }, [cookiesAccepted]);

  const handleAccept = () => {
    setCookiesAccepted(true);
    setCookie('cookiesConsent', 'accepted', nbDaysExpirationCookie);
  };

  const handleDecline = () => {
    setCookiesDeclined(true);
    setCookie('cookiesConsent', 'declined', nbDaysExpirationCookie);
  };

  if (cookiesAccepted || cookiesDeclined) {
    return null;
  }

  return (
    <Banner>
      <BannerText>
        Nous utilisons des cookies pour améliorer votre expérience. En cliquant sur
        &quot;Accepter&quot;, vous consentez à notre utilisation des cookies.
      </BannerText>

      <ButtonsWrapper>
        <Button
          variant="outline"
          size={'sm'}
          borderRadius={'6px'}
          background={'transparent'}
          onClick={handleDecline}>
          Refuser
        </Button>
        <Button
          borderRadius={'6px'}
          borderWidth="2px"
          borderColor={colors.primary}
          size={'sm'}
          variant="primary"
          onClick={handleAccept}>
          Accepter
        </Button>
      </ButtonsWrapper>
    </Banner>
  );
};

export default CookieConsentBanner;

const Banner = styled.div`
  background-color: ${colors.primary50};
  color: ${colors.primary};
  padding: 16px;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
`;

const BannerText = styled.div`
  margin-bottom: 16px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
